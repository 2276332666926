import { Card, CardActions, CardContent, Typography } from '@mui/material';

import { GoldenBookAwardee } from '../../../../lib/types';
import DeleteAwardeeDialogButton from '../dialogs/DeleteAwardeeDialogButton';
import EditAwardeePublicDataDialogButton from '../dialogs/EditAwardeePublicDataDialogButton';

interface Props {
  awardee: GoldenBookAwardee;
  isSecretary: boolean;
}

const AwardeePublicDataDisplay: React.FC<Props> = ({
  awardee,
  isSecretary,
}) => (
  <Card variant="outlined">
    <CardContent>
      <Typography sx={{ fontSize: 14 }} color="text.secondary" mb={2}>
        Dati pubblici
      </Typography>
      <Typography variant="h4" gutterBottom>
        {awardee.lastName} {awardee.firstName}
      </Typography>
    </CardContent>
    {isSecretary && (
      <CardActions>
        <EditAwardeePublicDataDialogButton awardee={awardee} />
        <DeleteAwardeeDialogButton awardee={awardee} />
      </CardActions>
    )}
  </Card>
);

export default AwardeePublicDataDisplay;
