import { useMemo } from 'react';

import { Button, DialogActions, Grid, InputProps } from '@mui/material';

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { useGoldenBookEntry } from '../../../../lib/context/useGoldenBookEntry';
import { useUser } from '../../../../lib/context/useUser';
import useNotification from '../../../../lib/hooks/useNotification';
import { AwardeePrivateData } from '../../../../lib/types';
import TextInput from '../../../common/inputs/TextInput';
import PhotoInput from '../inputs/PhotoInput';

interface FormData {
  email?: string;
  phone?: string;
  birthYear?: number;
  address?: string;
  city?: string;
  photo?: string;
  observations?: string;
}

interface Props {
  handleClose: () => void;
  awardeePrivateData: AwardeePrivateData;
}

const EditAwardeePrivateDataForm: React.FC<Props> = ({
  handleClose,
  awardeePrivateData,
}) => {
  const { error } = useNotification();
  const { editAwardeePrivateData } = useGoldenBookEntry();
  const { isSecretary } = useUser();

  const onSubmit: SubmitHandler<FormData> = (data) => {
    const updatedAwardeePrivateData: AwardeePrivateData = {
      id: awardeePrivateData.id,
      email: data.email,
      phone: data.phone,
      birthYear: data.birthYear,
      address: data.address,
      city: data.city,
      photo: data.photo?.length ? data.photo : undefined,
      observations: data.observations,
    };

    editAwardeePrivateData(updatedAwardeePrivateData)
      .then(handleClose)
      .catch(error('Errore durante la modifica dei dati privati del premiato'));
  };

  const defaultValues: FormData = {
    email: awardeePrivateData.email,
    phone: awardeePrivateData.phone,
    birthYear: awardeePrivateData.birthYear,
    address: awardeePrivateData.address,
    city: awardeePrivateData.city,
    photo: awardeePrivateData.photo,
    observations: awardeePrivateData.observations,
  };

  const formMethods = useForm<FormData>({ defaultValues });

  const onCancel = () => {
    handleClose();
    formMethods.reset();
  };

  const readOnly: Partial<InputProps> = useMemo(
    () => ({ readOnly: !isSecretary }),
    [isSecretary]
  );

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextInput InputProps={readOnly} name="email" label="Email" />
          </Grid>
          <Grid item xs={8}>
            <TextInput
              InputProps={readOnly}
              name="phone"
              label="Numero di telefono"
            />
          </Grid>
          <Grid item xs={4}>
            <TextInput
              InputProps={readOnly}
              name="birthYear"
              label="Anno di nascita"
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput InputProps={readOnly} name="address" label="Indirizzo" />
          </Grid>
          <Grid item xs={7}>
            <TextInput InputProps={readOnly} name="city" label="Città" />
          </Grid>
          <Grid item xs={5}>
            <PhotoInput name="photo" label="Carica Foto" />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              multiline
              InputProps={readOnly}
              name="observations"
              label="Osservazioni"
            />
          </Grid>
        </Grid>
        {isSecretary && (
          <DialogActions sx={{ mt: 1 }}>
            <Button onClick={onCancel}>Annulla</Button>
            <Button
              type="submit"
              autoFocus
              disabled={!formMethods.formState.isDirty}
            >
              Salva
            </Button>
          </DialogActions>
        )}
      </form>
    </FormProvider>
  );
};

export default EditAwardeePrivateDataForm;
