import axios, { AxiosError } from 'axios';
import { CipherData } from 'easy-web-crypto';

import { BACKEND_URL } from '../config';
import {
  Award,
  AwardEdition,
  Candidacy,
  CandidacyIndexInput,
  CandidacyWithIndex,
  GoldenBookAwardee,
  GoldenBookEntry,
  GoldenBookEntryInput,
  Judge,
  JudgeGrade,
  JudgeGradeInput,
  JudgeGradesAndStatus,
  Ranking,
  User,
  WeightedCriterion,
} from './types';
import { log } from './utils/log';

const axiosClient = axios.create({
  baseURL: BACKEND_URL,
  withCredentials: true,
  withXSRFToken: true,
});

const apiClient = {
  async __get<T>(path: string): Promise<T> {
    return axiosClient.get(path).then((res) => res.data as unknown as T);
  },

  async __put<T, R>(path: string, body: T): Promise<R> {
    return axiosClient.put(path, body).then((res) => res.data as unknown as R);
  },

  async __post<T, R>(path: string, body: T): Promise<R> {
    return axiosClient.post(path, body).then((res) => res.data as unknown as R);
  },

  async __delete<T>(path: string): Promise<T> {
    return axiosClient.delete(path).then((res) => res.data as unknown as T);
  },

  async getWhoami(): Promise<User> {
    return this.__get('/whoami');
  },

  async getAwardEditions(): Promise<AwardEdition[]> {
    return this.__get('/awardEditions');
  },

  async getCandidacies(awardEditionId: number): Promise<CandidacyWithIndex[]> {
    return this.__get(`/awardEditions/${awardEditionId}/candidacies`);
  },

  async getJudges(awardEditionId: number): Promise<Judge[]> {
    return this.__get(`/awardEditions/${awardEditionId}/judges`);
  },

  async getCriteria(awardEditionId: number): Promise<WeightedCriterion[]> {
    return this.__get(`/awardEditions/${awardEditionId}/criteria`);
  },

  async getJudgeGradesAndStatus(
    awardEditionId: number
  ): Promise<JudgeGradesAndStatus> {
    return this.__get(`/awardEditions/${awardEditionId}/judgeGrades`);
  },

  async putJudgeGrade(
    awardEditionId: number,
    grade: JudgeGradeInput
  ): Promise<JudgeGrade> {
    return this.__put(`/awardEditions/${awardEditionId}/judgeGrades`, grade);
  },

  async postCompleteJudgeGrade(awardEditionId: number): Promise<string> {
    return this.__post(
      `/awardEditions/${awardEditionId}/judgeGrades/complete`,
      undefined
    );
  },

  async postStartVoting(awardEditionId: number): Promise<string> {
    return this.__post(
      `/awardEditions/${awardEditionId}/startVoting`,
      undefined
    );
  },

  async postCompleteVoting(awardEditionId: number): Promise<string> {
    return this.__post(
      `/awardEditions/${awardEditionId}/completeVoting`,
      undefined
    );
  },

  async getRanking(awardEditionId: number): Promise<Ranking> {
    return this.__get(`/awardEditions/${awardEditionId}/ranking`);
  },

  async postCandidacy(
    awardEditionId: number,
    newCandidacy: Candidacy
  ): Promise<CandidacyWithIndex> {
    const createdCandidacy: Candidacy = await this.__post(
      `/candidacies`,
      newCandidacy
    );
    return this.__post(`/awardEditions/${awardEditionId}/candidacies`, {
      id: createdCandidacy.id,
    });
  },

  async putCandidacyIndex(
    awardEditionId: number,
    candidacyIndexInput: CandidacyIndexInput
  ): Promise<string> {
    return this.__put(
      `/awardEditions/${awardEditionId}/candidacies`,
      candidacyIndexInput
    );
  },

  async putCandidacy(updatedCandidacy: Candidacy): Promise<Candidacy> {
    return this.__put(`/candidacies/${updatedCandidacy.id}`, updatedCandidacy);
  },

  async deleteCandidacy(
    awardEditionId: number,
    candidacyId: number
  ): Promise<string> {
    await this.__delete(`/candidacies/${candidacyId}`);
    return this.__delete(
      `/awardEditions/${awardEditionId}/candidacies/${candidacyId}`
    );
  },

  async getPublicGoldenBookEntries(): Promise<GoldenBookEntry[]> {
    return this.__get('/public/goldenbook/entries');
  },

  async getPublicAwards(): Promise<Award[]> {
    return this.__get('/public/awards');
  },

  login(): void {
    const top = Math.round(window.innerHeight / 2 - 700 / 2);
    const left = Math.round(window.innerWidth / 2 - 600 / 2);
    const features = `popup, width=600, height=700, top=${top}, left=${left}, toolbar=no, menubar=no`;
    const loginPage = `${BACKEND_URL}oauth2/authorization/keycloak`;
    log(`Opening login page at: ${loginPage}`);
    window.open(loginPage, '_self', features);
  },

  async logout(): Promise<void> {
    return this.__post('/logout', undefined);
  },

  async getEncryptedMainKey(): Promise<CipherData> {
    return this.__get('/goldenbook/userKey');
  },

  async getGoldenBookEntries(): Promise<GoldenBookEntry[]> {
    return this.__get('/goldenbook/entries');
  },

  async getGoldenBookEntry(id: number): Promise<GoldenBookEntry> {
    return this.__get(`/goldenbook/entries/${id}`);
  },

  async postGoldenBookEntry(
    newEntry: GoldenBookEntryInput
  ): Promise<GoldenBookEntry> {
    return this.__post('/goldenbook/entries', newEntry);
  },

  async putGoldenBookEntry(
    updatedEntry: GoldenBookEntryInput
  ): Promise<GoldenBookEntry> {
    return this.__put(`/goldenbook/entries/${updatedEntry.id}`, updatedEntry);
  },

  async postAwardee(newAwardee: GoldenBookAwardee): Promise<GoldenBookAwardee> {
    return this.__post('/goldenbook/awardees', newAwardee);
  },

  async putAwardee(
    updatedAwardee: GoldenBookAwardee
  ): Promise<GoldenBookAwardee> {
    return this.__put(
      `/goldenbook/awardees/${updatedAwardee.id}`,
      updatedAwardee
    );
  },

  async deleteAwardee(awardeeId: number): Promise<void> {
    return this.__delete(`/goldenbook/awardees/${awardeeId}`);
  },

  async getAwardeePrivateData(
    awardeeId: number
  ): Promise<CipherData | undefined> {
    try {
      const privateData: CipherData = await this.__get(
        `/goldenbook/awardees/${awardeeId}/personalData`
      );
      return privateData;
    } catch (err: unknown) {
      if ((err as AxiosError)?.response?.status === 404) {
        return undefined;
      }
    }
  },

  async postAwardeePrivateData(
    awardeeId: number,
    newAwardeePrivateData: CipherData
  ): Promise<CipherData> {
    return this.__post(
      `/goldenbook/awardees/${awardeeId}/personalData`,
      newAwardeePrivateData
    );
  },

  async putAwardeePrivateData(
    awardeeId: number,
    updatedAwardeePrivateData: CipherData
  ): Promise<CipherData> {
    return this.__put(
      `/goldenbook/awardees/${awardeeId}/personalData`,
      updatedAwardeePrivateData
    );
  },
};

export default apiClient;
