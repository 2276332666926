import { useState } from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { IconButton } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
  GridValidRowModel,
} from '@mui/x-data-grid';

import { Link } from 'react-router-dom';

import { useUser } from '../../../lib/context/useUser';
import StripedDataGrid from '../../common/lists/StripedDataGrid';
import PrizeIcon from '../../secretary/ranking/PrizeIcon';
import GoldenBookTableToolbar from './GoldenBookTableToolbar';

export interface GoldenBookTableRow {
  id: number;
  lastName: string;
  firstName: string;
  year: string;
  awardName?: string;
  published: boolean;
}

interface Props {
  rows: GoldenBookTableRow[];
}

const GoldenBookTable: React.FC<Props> = ({ rows }) => {
  const { isSecretaryOrBoardMember } = useUser();

  const columns: GridColDef<GoldenBookTableRow>[] = [
    {
      field: '_',
      headerName: '',
      sortable: false,
      align: 'center',
      display: 'flex',
      renderCell: () => (
        <IconButton disabled>
          <PrizeIcon fontSize="large" rank={1} hideRankNumber />
        </IconButton>
      ),
    },
    {
      field: 'lastName',
      headerName: 'Cognome',
      flex: 1,
      display: 'flex',
    },
    {
      field: 'firstName',
      headerName: 'Nome',
      flex: 1,
      display: 'flex',
    },
    {
      field: 'year',
      headerName: 'Anno',
      width: 120,
      display: 'flex',
    },
    {
      field: 'awardName',
      headerName: 'Premio',
      flex: 2,
      display: 'flex',
    },
    // Only show link to entry page for secretary and board members
    ...((isSecretaryOrBoardMember
      ? [
          {
            field: 'id',
            headerName: '',
            sortable: false,
            align: 'center',
            display: 'flex',
            renderCell: (
              params: GridRenderCellParams<GoldenBookTableRow, number>
            ) => (
              <Link
                style={{ textDecoration: 'none', color: 'inherit ' }}
                to={`/albodoro/${params.row.id}`}
              >
                <IconButton>
                  <ArrowForwardIcon />
                </IconButton>
              </Link>
            ),
          },
        ]
      : []) as GridColDef<GoldenBookTableRow>[]),
  ];

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'year',
      sort: 'desc',
    },
  ]);

  return (
    <StripedDataGrid
      sx={{ mt: 3 }}
      rows={rows}
      columns={columns as GridColDef<GridValidRowModel>[]}
      sortModel={sortModel}
      onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
      disableRowSelectionOnClick
      loading={rows.length === 0}
      slots={{ toolbar: GoldenBookTableToolbar }}
      slotProps={{
        loadingOverlay: { noRowsVariant: 'skeleton' },
        toolbar: { showQuickFilter: true },
      }}
      getRowClassName={(params) => {
        const { published, year } = params.row as GoldenBookTableRow;
        // Using `!== false` because published could be undefined
        const draft = published !== false ? '' : 'draft';
        const evenOrOdd = Number.parseInt(year) % 2 === 0 ? 'even' : 'odd';
        const striped = sortModel[0]?.field === 'year' ? evenOrOdd : '';
        return `${draft} ${striped}`;
      }}
      getRowHeight={() => 'auto'}
    />
  );
};

export default GoldenBookTable;
