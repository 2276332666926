import { useCallback, useMemo, useState } from 'react';

import { Button, DialogActions, Grid, InputProps } from '@mui/material';

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { GRANT_AWARD_IDS } from '../../../../config';
import { useGoldenBookEntry } from '../../../../lib/context/useGoldenBookEntry';
import { useUser } from '../../../../lib/context/useUser';
import useNotification from '../../../../lib/hooks/useNotification';
import { GoldenBookEntry, GoldenBookEntryInput } from '../../../../lib/types';
import CheckboxInput from '../../../common/inputs/CheckboxInput';
import TextInput from '../../../common/inputs/TextInput';

interface FormData {
  published: boolean;
  awardId?: number;
  otherAward?: string;
  year: string;

  amountWon?: number;
  present?: string;
  yearOfMasterGraduation?: string;
  projectOrDiplomaTitle?: string;
  awardPlace?: string;
  auditionPlace?: string;
  bestPresentationAward: boolean;
  observations?: string;
}

interface Props {
  handleClose: () => void;
  entry: GoldenBookEntry;
}

const EditAwardDataForm: React.FC<Props> = ({ entry, handleClose }) => {
  const { error } = useNotification();
  const { editEntry } = useGoldenBookEntry();
  const { isSecretary } = useUser();

  // This is ALWAYS true, because Fulvio wants to add extra information
  // to each award and requested to only use the "other award" free text
  // field. We leave this code in case he changes idea.
  const [isOtherAward] = useState(!!entry.otherAward);

  const defaultValues: FormData = {
    year: entry.year,
    awardId: entry.awardId ?? undefined,
    otherAward: entry.otherAward ?? undefined,
    published: entry.published,

    amountWon: entry.amountWon ?? undefined,
    present: entry.present ?? undefined,
    yearOfMasterGraduation: entry.yearOfMasterGraduation ?? undefined,
    projectOrDiplomaTitle: entry.projectOrDiplomaTitle ?? undefined,
    awardPlace: entry.awardPlace ?? undefined,
    auditionPlace: entry.auditionPlace ?? undefined,
    bestPresentationAward: entry.bestPresentationAward,
    observations: entry.observations ?? undefined,
  };

  const formMethods = useForm<FormData>({ defaultValues });

  const selectedAwardId = formMethods.watch('awardId');

  const onCancel = () => {
    handleClose();
    formMethods.reset();
  };

  const readOnly: Partial<InputProps> = useMemo(
    () => ({ readOnly: !isSecretary }),
    [isSecretary]
  );

  // Because `isOtherAward` is always true, this is ALWAYS false.
  // Again, we leave this code here incase Fulvio changes idea.
  const isGrantAward: boolean = useMemo(
    () => !isOtherAward && GRANT_AWARD_IDS.includes(selectedAwardId || -1),
    [isOtherAward, selectedAwardId]
  );

  const onSubmit: SubmitHandler<FormData> = useCallback(
    (data) => {
      const updatedEntry: GoldenBookEntryInput = {
        ...entry,
        awardeeId: `${entry.awardee.id}`,

        published: data.published,
        awardId: isOtherAward ? undefined : data.awardId,
        otherAward: isOtherAward ? data.otherAward : undefined,
        year: data.year,

        amountWon: data.amountWon || undefined,
        present: data.present || undefined,
        yearOfMasterGraduation: isGrantAward
          ? data.yearOfMasterGraduation
          : undefined,
        projectOrDiplomaTitle: isGrantAward
          ? undefined
          : data.projectOrDiplomaTitle,
        awardPlace: data.awardPlace || undefined,
        auditionPlace: data.auditionPlace || undefined,
        bestPresentationAward: data.bestPresentationAward,
        observations: data.observations || undefined,
      };
      editEntry(updatedEntry)
        .then(handleClose)
        .catch(error('Errore durante la modifica del premiato'));
    },
    [editEntry, entry, error, handleClose, isGrantAward, isOtherAward]
  );

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CheckboxInput
              disabled={!isSecretary}
              name="published"
              label="Pubblicato"
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              multiline
              InputProps={readOnly}
              name="otherAward"
              label="Altro premio"
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput InputProps={readOnly} name="year" label="Anno" />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              InputProps={readOnly}
              name="amountWon"
              label="Vincita CHF"
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              InputProps={readOnly}
              name="present"
              label="Vincita (altro)"
            />
          </Grid>
          {/* Show only for grants */}
          {isGrantAward && (
            <Grid item xs={12}>
              <TextInput
                InputProps={readOnly}
                name="yearOfMasterGraduation"
                label="Anno di diploma Master"
              />
            </Grid>
          )}
          {/* Show only for NON grants */}
          {!isGrantAward && (
            <Grid item xs={12}>
              <TextInput
                InputProps={readOnly}
                name="projectOrDiplomaTitle"
                label="Titolo diploma o progetto"
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextInput
              InputProps={readOnly}
              name="awardPlace"
              label="Luogo premiazione"
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              InputProps={readOnly}
              name="auditionPlace"
              label="Luogo audizione"
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              multiline
              InputProps={readOnly}
              name="observations"
              label="Osservazioni"
            />
          </Grid>
        </Grid>
        {isSecretary && (
          <DialogActions sx={{ mt: 1 }}>
            <Button onClick={onCancel}>Annulla</Button>
            <Button
              type="submit"
              autoFocus
              disabled={!formMethods.formState.isDirty}
            >
              Salva
            </Button>
          </DialogActions>
        )}
      </form>
    </FormProvider>
  );
};

export default EditAwardDataForm;
