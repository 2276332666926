import { Typography } from '@mui/material';

import { useNavigate } from 'react-router-dom';

import apiClient from '../../../../lib/apiClient';
import useNotification from '../../../../lib/hooks/useNotification';
import { GoldenBookAwardee } from '../../../../lib/types';
import ConfirmationDialogButton from '../../../common/dialogs/ConfirmationDialogButton';

interface Props {
  awardee: GoldenBookAwardee;
}

const DeleteAwardeeDialogButton: React.FC<Props> = ({ awardee }) => {
  const { info, error } = useNotification();
  const navigate = useNavigate();

  const redirectToGoldenBook = () => navigate('/albodoro');

  return (
    <ConfirmationDialogButton
      action={() =>
        apiClient
          .deleteAwardee(awardee.id)
          .then(redirectToGoldenBook)
          .then(info(`Eliminato ${awardee.lastName} ${awardee.firstName}.`))
          .catch(error("Errore durante l'eliminazione del premiato."))
      }
      dialog={{
        title: 'Eliminare premiato?',
        description:
          'Eliminando il premiato verranno eliminati tutti i dati associati: dati pubblici, dati del premio, dati privati (crittografati).',
        okText: 'Elimina premiato',
      }}
      tooltip="Elimina premiato"
      variant="text"
      color="error"
      size="small"
    >
      <Typography variant="body2">Elimina</Typography>
    </ConfirmationDialogButton>
  );
};

export default DeleteAwardeeDialogButton;
